<template>
<v-dialog v-model="monthReport" width="915" persistent content-class="monthly_report">
  <v-card>
    <v-card-title class="monthly_p_0">
      <!-- toolbar -->
      <v-system-bar class="modal-header p_0_15">
        <v-layout d-flex align-center justify-start row class="width100 m_0">
          <v-flex v-if="monthlyReportDataEdit" grow class="header-title">编辑月报</v-flex>
          <v-flex v-else grow class="header-title">写月报</v-flex>
          <v-flex shrink class="header-close">
            <v-icon class="header-close" @click="monthReport=false">mdi-close</v-icon>
          </v-flex>
        </v-layout>
      </v-system-bar>
    </v-card-title>
    <v-card-text class="pb_0">
      <v-container d-flex>
        <v-layout align-center justify-center column class="width100 m_0">
          <v-flex class="width100">
            <v-layout d-flex align-center justify-start row class="width100 m_0 mb_20">
              <v-flex shrink><span class="content-date">月报日期</span></v-flex>
              <!-- year -->
              <v-flex shrink class="mr_10">
                <v-select :items="years" item-value="dbformat" item-text="title" hide-details class="selectOption" v-model="monthlyYear"></v-select>
              </v-flex>
              <!-- month -->
              <v-flex shrink class="mr_10">
                <v-select :items="months" item-value="value" item-text="text" hide-details class="selectOption" v-model="monthlyMonth"></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="width100">
            <!-- show tasks start -->
            <div class="d-flex">
              <div v-if="!showTask" class="d-flex">
                <div class="align-center d-flex">
                  <span class="text-subtitle-1">进行中任务</span>
                  <span :class="doingTasks.length == 0 ? 'text-subtitle-1 ml-1' : 'text-subtitle-1 ml-1 blue--text'">{{ doingTasks.length }}</span>
                  <v-icon class="ml-1">mdi-help-circle</v-icon>
                </div>
                <div class="align-center d-flex mx-2">
                  <span class="text-subtitle-1">过期任务</span>
                  <span :class="latedTasks.length == 0 ? 'text-subtitle-1 ml-1' : 'text-subtitle-1 ml-1 red--text'">{{ latedTasks.length }}</span>
                </div>
                <div class="align-center d-flex mx-2">
                  <span class="text-subtitle-1">完成任务</span>
                  <span :class="completedTasks.length == 0 ? 'text-subtitle-1 ml-1' : 'text-subtitle-1 ml-1 green--text'">{{ completedTasks.length }}</span>
                </div>
              </div>
              <v-spacer></v-spacer>
              <div v-if="!showTask" @click="showTask=true" style="cursor:pointer;" class="blue--text d-flex align-center">
                <div>查看详情</div>
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
              <div @click="showTask=false" v-else style="cursor:pointer;" class="blue--text d-flex align-center">
                <div>收起</div>
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-up</v-icon>
              </div>
            </div>
          </v-flex>
          <v-container>
            <div style="max-height:400px;overflow:auto;" v-if="showTask">
              <div v-if="doingTasks.length>0">
                <div class="text-subtitle-1">进行中任务 <span class="blue--text">{{ doingTasks.length }}</span></div>
                <div class="pl-5 blue--text" :key="i" v-for="(task, i) in doingTasks">{{ task.task_name }}</div>
              </div>
              <div v-if="latedTasks.length>0">
                <div class="text-subtitle-1">过期任务 <span class="red--text">{{ latedTasks.length }}</span></div>
                <div class="pl-5 red--text" :key="i" v-for="(task, i) in latedTasks">{{ task.task_name }}</div>
              </div>
              <div v-if="completedTasks.length>0">
                <div class="text-subtitle-1">完成任务 <span class="green--text">{{ completedTasks.length }}</span></div>
                <div class="pl-5 green--text" :key="i" v-for="(task, i) in completedTasks">{{ task.task_name }}</div>
              </div>
            </div>
          </v-container>
          <!-- show tasks end -->
          <v-flex class="width100">
            <v-layout d-flex align-center justify-center column class="width100 m_0 mb_30 monthly_summary_obstacle">
              <v-flex class="title_obstacle width100"><span>总结与障碍</span></v-flex>
              <!-- rich editor -->
              <v-flex class="content_obstacle width100">
                <EditorContent :content="dailySummaryObstacle" @input="handle_input_summary" isShow="false"/>
                <!-- <my-rich-editor></my-rich-editor> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="width100">
            <v-layout d-flex align-center justify-center column class="width100 m_0 mb_30 monthly_next_work">
              <v-flex class="title_nextWork width100"><span>下一步工作安排</span></v-flex>
              <!-- rich editor -->
              <v-flex class="content_nextWork width100">
                <EditorContent :content="dailyNextWork" @input="handle_input_next" isShow="false"/>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="width100">
            <v-layout d-flex align-center justify-center column class="width100 m_0 mb_30 monthly_file_upload">
              <!-- file upload -->
              <v-container>
                <div v-if="!uploaded" class="d-flex">
                  <div>上传附件</div>
                  <div style="cursor:pointer;" class="blue--text ml-2" @click="openFiles">点击上传</div>
                  <input ref="fileUpload" style="display:none;" type="file" @change="onFileChanged">
                  <v-spacer></v-spacer>
                </div>
                <div>
                  <v-chip class="ml-1" @click:close="files.splice(i, 1)" close label color="cyan" text-color="white" v-for="(file, i) in files">{{ file.name }}</v-chip>
                </div>
              </v-container>
              <!-- file upload end -->
            </v-layout>
          </v-flex>
          <v-flex class="width100">
            <v-layout d-flex align-center justify-center row class="width100 m_0 mb_10 daily_visibleRange">
              <v-flex shrink class="title_visibleRange"><span class="mr_30">可见范围</span></v-flex>
              <v-flex grow>
                <v-radio-group v-model="dailyVisibleRange" row>
                  <v-radio v-for="(item, index) in visibleRange" :key="index" :label="item" :value="index" class="visible_item"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="width100 ta_contacts">
            <v-layout d-flex align-start justify-center row class="width100 m_0 p_10">
              <v-flex shrink>
                <span class="contacts_title">@ta查看</span>
              </v-flex>
              <v-flex grow>
                <v-layout d-flex align-center justify-start column class="width100 m_0">
                  <v-flex class="width100">
                    <v-layout d-flex align-center justify-start row class="width100 m_0">
                      <v-flex shrink style="margin-right:10px;">
                        <v-avatar v-for="(contact,index) in monthlyTaContacts" :key="contact" color="warning lighten-2" size="32" class="contact_item">{{ contact | surename }}
                          <v-icon size="15" class="remove_contact_item" @click="removeContactItem(index)">mdi-close-circle</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex grow>
                        <v-menu max-width="300" :close-on-content-click="true" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class='contacts_plus'>
                              <v-icon color="#3aab6e" size="35">mdi-plus-circle-outline</v-icon>
                            </span>
                          </template>
                          <v-sheet>
                            <UserPicker @pick="pickUser" />
                          </v-sheet>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-divider class="width100"></v-divider>
                  <v-flex class="width100">
                    <v-checkbox label="同时向ta发送邮件" hide-details class="weekly_checkbox" v-model="monthlyTaMail"></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions class="pt_0 pb_20 mt_20">
      <v-layout d-flex align-center justify-start row class="width100 m_0">
        <v-flex grow d-flex align-center justify-end class="mr_20">
          <v-btn @click="monthReport=false">取消</v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" :disabled="submitBtnStatus" @click="submitMonthlyReport">提交</v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import EditorContent from '@/components/okrgoal/rightInfo/editorContent/EditorContent.vue';
import UserPicker from '@/components/common/UserPicker.vue';
import {
  constant
} from '@/constants/constant.js';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  components: {
    EditorContent,
    UserPicker,
    // MyRichEditor: () => import('@/components/common/MyRichEditor.vue')
  },
  data() {
    return {
      dailyTaskProgress: 0,
      dailyTaskOverdue: 0,
      dailyTaskMission: 0,
      dailySummaryObstacle: null,
      dailyNextWork: null,
      dailyVisibleRange: 0,
      monthReport: false,
      monthlyYear: null,
      monthlyMonth: null,
      monthlyTaContacts: [],
      monthlyTaMail: false,
      monthlyReportDataEdit: false,
      editRpID: null,
      years: [],
      months: [],
      files: [], // upload
      uploaded: false, // upload
      showTask: false,      // task
      monthStart: null,      // task
      monthEnd: null,        // task
      visibleRange: ['仅相关成员', '仅部门可见', '全公司']
    }
  },
  created() {
    const date = new Date();
    this.monthlyYear = date.getFullYear();
    this.monthlyMonth = date.getMonth();
    this.years = constant.yearList;
    for (let i = 1; i < 13; i++) {
      this.months.push({
        value: i,
        text: `${i}月`
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('task', ['tasks']), //task
    // visibleRange() {
    //   return constant.visibleRange;
    // },
    submitBtnStatus() {
      if(this.dailySummaryObstacle == '') {
        this.dailySummaryObstacle = `<p></p>`;
      }
      if(this.dailyNextWork == '') {
        this.dailyNextWork = `<p></p>`;
      }
      return this.dailySummaryObstacle == `<p></p>` && this.dailyNextWork == `<p></p>`;
    },
    //  task start
    thisMonthTasks: function () {
      return this.tasks.filter(task => {
        const start = new Date(this.monthStart);
        const end = new Date(this.monthEnd);        
        const taskEndDate = new Date(task.task_end_date);
        if(start.getTime() < taskEndDate.getTime() && end.getTime() > taskEndDate.getTime()) {
          return task;
        }
      })
    },
    completedTasks: function () {
      return this.thisMonthTasks.filter(task => {
        if (task.task_status == 1) {
          return task;
        }
      })
    },
    latedTasks: function () {
      return this.thisMonthTasks.filter(task => {
        const now = new Date().getTime();
        const taskTime = new Date(task.task_end_date).getTime();
        if (taskTime < now && task.task_status != 1) {
          return task;
        }
      })
    },
    doingTasks: function () {
      return this.thisMonthTasks.filter(task => {
        const now = new Date().getTime();
        const taskTime = new Date(task.task_end_date).getTime();
        if (taskTime >= now && task.task_status != 1) {
          return task;
        }
      })
    }
  },
  methods: {
    ...mapActions('report', ['addMonthlyReport', 'updateMonthlyReport']),
    ...mapActions('fileUpload', ['filesUpload']), // upload
    ...mapActions('task', ['getTasks']), // task
    displayMonthlyReportModal(prop) {
      this.files = []; // upload
      // task
      this.getTasks({
        task_owner: this.authUser.id
      });
      // task
      if (prop.edit == true) {
        this.monthlyReportDataEdit = true;
        this.editRpID = prop.rp.rp_id;
        this.monthlyYear = prop.rp.rp_date.split("-")[0];
        if (parseInt(prop.rp.rp_date.split("-")[1]) < 10) {
          this.monthlyMonth = prop.rp.rp_date.split("-")[1].split("0")[1] + "月";
        } else {
          this.monthlyMonth = prop.rp.rp_date.split("-")[1] + "月";
        }
        let date = new Date(prop.rp.rp_date);
        this.dailySummaryObstacle = prop.rp.rp_abstract;
        this.dailyNextWork = prop.rp.rp_todo;
        this.dailyVisibleRange = prop.rp.rp_visible_range;
        this.monthlyTaContacts = prop.rp.rp_ta_contacts.split(",").filter(u => u != '');
        this.uploaded = !!prop.rp.rp_attatch_files; // upload
      } else {
        this.monthlyReportDataEdit = false;
        this.dailySummaryObstacle = `<p></p>`;
        this.dailyNextWork = `<p></p>`;
        this.uploaded = false; // upload
        this.dailyVisibleRange = 0;
      }
      this.monthReport = true;
      this.monthlyYear = Number(prop.rp_date.year);
      this.monthlyMonth = Number(prop.rp_date.month);
      this.monthStart = new Date(this.monthlyYear, this.monthlyMonth-1, 1);
      this.monthEnd = new Date(this.monthlyYear, this.monthlyMonth, 0);
    },
    handle_input_summary(prop) {
      this.dailySummaryObstacle = prop.prgValue;
    },
    handle_input_next(prop) {
      this.dailyNextWork = prop.prgValue;
    },
    setYearOfMonthly(e) {
      this.monthlyYear = e;
    },
    setMonthOfMonthly(e) {
      this.monthlyMonth = e;
    },
    pickUser(e) {
      this.monthlyTaContacts.push(e.user.id);
    },
    removeContactItem(e) {
      this.monthlyTaContacts.splice(e, 1);
    },
    submitMonthlyReport() {
      // file upload
      if (this.files.length > 0) {
        let formData = new FormData()
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i]
          formData.append('files', file)
        }
        this.filesUpload(formData).then(res => {
          let fileIds = []
          res.map(file => {
            fileIds.push(file.file_id)
          })
          this.submit(fileIds.join());
        })
      } else {
        this.submit();
      }
    },
    submit(files = null) {
      let dailyReportDate = this.monthlyYear + "-" + this.monthlyMonth;
      if (this.monthlyReportDataEdit) {
        let param = {
          "rp_id": this.editRpID,
          "rp_date": dailyReportDate,
          "rp_abstract": this.dailySummaryObstacle.toString(),
          "rp_todo": this.dailyNextWork.toString(),
          "rp_visible_range": this.dailyVisibleRange,
          "rp_ta_contacts": this.monthlyTaContacts.toString(),
          "rp_publish_type": 1,
          "rp_creator": this.authUser.id,
          "rp_attatch_files": files,
          "rp_progress_tasks": this.doingTasks.length,          // task
          "rp_expired_tasks": this.latedTasks.length,           // task
          "rp_completed_tasks": this.completedTasks.length      // task
        }
        this.updateMonthlyReport(param).then(res => {
          this.$emit("displayRightSummaryEdit", res);
        })

      } else {
        let param = {
          "rp_type": 2,
          "rp_date": dailyReportDate,
          "rp_abstract": this.dailySummaryObstacle.toString(),
          "rp_todo": this.dailyNextWork.toString(),
          "rp_visible_range": this.dailyVisibleRange,
          "rp_ta_contacts": this.monthlyTaContacts.toString(),
          "rp_publish_type": 1,
          "rp_creator": this.authUser.id,
          "rp_attatch_files": files,
          "rp_progress_tasks": this.doingTasks.length,          // task
          "rp_expired_tasks": this.latedTasks.length,           // task
          "rp_completed_tasks": this.completedTasks.length      // task
        }
        this.addMonthlyReport(param).then(res => {
          this.$emit("displayRightSummaryEdit", res);
        })
      }
      this.monthReport = false;
    },
    //file upload
    openFiles() {
      this.$refs.fileUpload.click();
    },
    onFileChanged(e) {
      this.files.push(e.target.files[0]);
    }
    // file upload end
  }
}
</script>

<style>
.width100 {
  width: 100%;
}

.m_0 {
  margin: 0px;
}

.p_0_15 {
  padding: 0px 15px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_10 {
  margin-bottom: 10px !important;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.mr_30 {
  margin-right: 30px;
}

.mr_10 {
  margin-right: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.pb_0 {
  padding-bottom: 0px;
  position: relative;
  top: 45px;
}

.pb_20 {
  padding-bottom: 20px;
}

.pt_0 {
  padding-top: 0px;
}

.p_10 {
  padding: 10px;
}

.mr_20 {
  margin-right: 20px !important;
}

.monthly_report {
  height: 845px;
}

/* .monthly_report::-webkit-scrollbar {
  display: none;
} */

.modal-header {
  background: linear-gradient(90deg, #1D86F0 6%, #5CADFF 94%);
  height: 40px !important;
  width: 100% !important;
}

.monthly_p_0 {
  padding: 0px !important;
  position: fixed;
  width: 915px;
  z-index: 1;
}

.header-title {
  font-size: 17.5px;
  color: #fff;
}

.header-close {
  font-size: 21px !important;
  color: #fff !important;
  cursor: pointer;
}

.content-date {
  color: #70808B;
  font-size: 14px;
  margin-right: 20px;
}

.daily_calendar {
  position: relative;
  bottom: 2px;
}

.daily_date {
  margin-right: 10px;
  color: #36434D;
}

.daily_date_picker {
  top: 118px !important;
}

.monthly_task-progress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 40px;
}

.monthly_task-overdue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 40px;
}

.monthly_task-mission {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.monthly_task-progress .title {
  font-size: 18px !important;
  color: #35434C;
  margin-right: 5px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.monthly_task-overdue .title {
  font-size: 18px !important;
  color: #35434C;
  margin-right: 5px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.monthly_task-mission .title {
  font-size: 18px !important;
  color: #35434C;
  margin-right: 5px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.monthly_task-progress .value {
  font-size: 23px;
  color: #A9B8BF;
  margin-right: 5px;
}

.monthly_task-overdue .value {
  font-size: 23px;
  color: #A9B8BF;
  margin-right: 5px;
}

.monthly_task-mission .value {
  font-size: 23px;
  color: #A9B8BF;
  margin-right: 5px;
}

.monthly_task-progress .help {
  font-size: 20px !important;
  color: #acb7bf !important;
  display: flex;
}

.monthly_summary_obstacle {
  border: 1px solid #E4EBF0;
}

.monthly_next_work {
  border: 1px solid #E4EBF0;
}

.monthly_summary_obstacle .title_obstacle {
  padding: 0 15px;
  color: #36434D;
  font-size: 16px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei" !important;
  height: 32px;
  display: flex;
  align-items: center;
}

.monthly_next_work .title_nextWork {
  padding: 0 15px;
  color: #36434D;
  font-size: 16px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei" !important;
  height: 32px;
  display: flex;
  align-items: center;
}

.daily_visibleRange .title_visibleRange {
  color: #647481;
  font-size: 14px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.visible_item .v-label {
  font-size: 15px !important;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", SimSun, STHeitiSC, sans-serif !important;
  color: #36434D !important;
}

.selectOption {
  padding: 0px !important;
  margin: 0px !important;
}

.weekly_checkbox {
  padding: 0px;
  margin: 2px 0px 0px 0px;
}

.weekly_checkbox .v-icon {
  color: #82C4FF !important;
}

.weekly_checkbox .v-input--selection-controls__ripple {
  border-radius: 50% !important;
  cursor: pointer !important;
  ;
  height: 20px !important;
  position: absolute !important;
  transition: inherit !important;
  width: 20px !important;
  left: -5px !important;
  top: calc(50% - 17px) !important;
  margin: 7px !important;
}

.ta_contacts {
  background: #F5F8FA;
}

.contacts_title {
  color: #647481;
  margin-right: 15px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.contacts_plus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2px;
  cursor: pointer;
}

.contact_item {
  margin-right: 5px;
  position: relative;
}

.remove_contact_item {
  position: absolute !important;
  bottom: 0px;
  left: 0px;
  font-size: 30px !important;
  opacity: 0;
  transition: all ease .5s;
  cursor: pointer;
}

.contact_item:hover .remove_contact_item {
  opacity: 1;
}
</style>
