<template>
<v-container d-flex class="width100 p_0 summary_monthly">
  <v-layout align-start justify-start row class="width100 m_0">
    <v-flex grow>
      <v-container d-flex class="width100">
        <v-layout align-start justify-start column class="width100 m_0">
          <v-flex d-flex align-center justify-start class="width100">
            <span class="monthly_title">我的月报 </span>
            <span class="monthly_value">{{ pickedMonth.year }}年{{ pickedMonth.month }}月</span>
          </v-flex>
          <v-flex d-flex align-center justify-start class="width100">
            <v-btn v-if="!monthlyReport" color="primary" class="mt_20" @click="displayMonthlyReportModal({edit: false})">写月报</v-btn>
            <v-btn v-else color="primary" class="mt_20" @click="displayMonthlyReportModal({edit: true, rp: monthlyReport})">编辑月报</v-btn>
          </v-flex>
          <v-flex v-if="!!monthlyReport" d-flex align-center justify-start class="width100 mt_30">
            <TabFirstItem :active="activeMonths" :report="monthlyReport" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
          </v-flex>
          <v-flex v-else d-flex align-center justify-start class="width100 mt_30 empty_monthly_summary">
            <span class="width100 empty_contain">
              <p>您尚未提交当月月报</p>
              <p>总结工作更有助于效率提升哦~</p>
            </span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <!-- month calendar -->
    <v-flex shrink>
      <!-- <v-date-picker v-model="datePicker" type="month" locale="zh-cn" class="monthPicker"></v-date-picker> -->
      <month-calendar :expireds="expireds" :actives="activeMonths" @pick="pickMonth" :current="current"></month-calendar>
    </v-flex>
  </v-layout>
  <v-divider class="width100"></v-divider>
  <TabFirstItem :active="activeMonths" v-for="(rp, index) in monthlyReports" :key="index" :report="rp" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
  <MonthlyReportModal ref="monthlyReport" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
</v-container>
</template>

<script>
import TabFirstItem from '@/components/summary/TabFirstItem.vue';
import MonthlyReportModal from '@/components/summary/MonthlyReportModal.vue';
import {
  constant
} from '@/constants/constant.js';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  props: ['year', 'month'],
  data() {
    return {
      re_type: "monthly",
      datePicker: null,
      pickedMonth: null,
      current: null,
      activeMonths: [],
      expireds: []
    }
  },
  created() {
    const today = new Date();
    this.getAllMonthlyReportWithCreator({
      "rp_creator": this.authUser.id,
      "rp_type": 2
    });
    this.pickedMonth = {
      year: today.getFullYear(),
      month: today.getMonth() + 1
    }
    this.current = {
      year: today.getFullYear(),
      month: today.getMonth() + 1
    }
    this.getUsers();
  },
  watch: {
    year(newValue, oldValue) {
      this.re_year = newValue;
    },
    month(newValue, oldValue) {
      if (newValue < 10) {
        this.re_month = "0" + newValue;
      } else {
        this.re_month = newValue;
      }
      let param = {
        "rp_creator": this.authUser.id,
        "rp_date": this.re_year + "-" + this.re_month,
        "rp_type": 2,
      }
      this.getMonthlyReport(param);
    }
  },
  components: {
    MonthlyReportModal,
    TabFirstItem,
    MonthCalendar: () => import('@/components/summary/MonthCalendar.vue')
  },
  computed: {
    ...mapGetters('report', ['allMonthlyReport']),
    ...mapGetters('auth', ['authUser']),
    filteredReports: function () {
      this.expireds = [];
      this.activeMonths = [];
      return this.allMonthlyReport.filter(report => {
        const year = report.rp_date.split('-')[0];
        const month = report.rp_date.split('-')[1];
        const end = new Date(year, month, 1).getTime();
        const rpDate = new Date(report.created_at).getTime();
        if(rpDate > end) {
          this.expireds.push(report.rp_date);
        } else {
          this.activeMonths.push(report.rp_date);
        }        
        if (Number(year) == Number(this.pickedMonth.year)) {
          return report
        }
      });
    },
    monthlyReports: function () {
      return this.filteredReports.filter(report => {
        const month = report.rp_date.split('-')[1];
        if (Number(month) !== Number(this.pickedMonth.month)) {
          return report;
        }
      })
    },
    monthlyReport: function () {
      const index = this.filteredReports.findIndex(report => {
        const month = report.rp_date.split('-')[1];
        if (Number(month) === Number(this.pickedMonth.month)) {
          return report;
        }
      })
      return this.filteredReports[index];
    }
  },
  methods: {
    ...mapActions('report', ['getMonthlyReport', 'getAllMonthlyReportWithCreator']),
    ...mapActions('user', ['getUsers']),
    setDateOfMonthlyReport(prop) {
      this.pickedMonth = {
        year: prop.split('-')[0],
        month: prop.split('-')[1]
      }
      this.re_month = prop.split("-")[1];
      let param = {
        "rp_creator": this.authUser.id,
        "rp_date": this.re_year + "-" + this.re_month,
        "rp_type": 2,
      }
      this.getMonthlyReport(param);
    },
    handle_displayRightSummaryEdit(prop) {
      let param = {
        value: prop,
        type: "monthly",
      }
      this.$emit("displayRightSummaryEdit", param);
    },
    displayMonthlyReportModal(prop) {
      this.$refs.monthlyReport.displayMonthlyReportModal({
        ...prop,
        rp_date: this.pickedMonth
      });
    },
    pickMonth(e) {
      this.pickedMonth = e;
    }
  }
}
</script>

<style>
.width100 {
  width: 100%
}

.m_0 {
  margin: 0px;
}

.p_0 {
  padding: 0px;
}

.monthly_title {
  font-size: 20px !important;
  color: #555 !important;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif !important;
  margin-right: 10px;
}

.monthly_value {
  font-size: 20px !important;
  color: #555 !important;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif !important;
}

.mt_20 {
  margin-top: 20px;
}

.mt_30 {
  margin-top: 30px;
}

.summary_monthly {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty_monthly_summary {
  padding: 30px 25px;
  border: dashed 1px #c5c5c5;
}

.empty_contain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 22px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
  color: #c5c5c5;
}

.monthPicker .v-picker__title {
  display: none !important;
}
</style>
