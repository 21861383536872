<template>
<v-container d-flex class="width100 p_0 summary_weekly">
  <v-layout align-start justify-start row class="width100 m_0">
    <v-flex grow>
      <v-container d-flex class="width100">
        <v-layout align-start justify-start column class="width100 m_0">
          <v-flex d-flex align-center justify-start class="width100">
            <span class="weekly_title">我的周报</span>
            <!-- picked week text -->
            <span v-if="pickedWeek" class="weekly_value">{{ pickedWeek.year }}年{{ pickedWeek.month }}月{{ pickedWeek.week }}周</span>
          </v-flex>
          <v-flex d-flex align-center justify-start class="width100">
            <v-btn v-if="!weeklyReport" color="primary" class="mt_20" @click="displayWeeklyReportModal({edit: false})">写周报</v-btn>
            <v-btn v-else color="primary" class="mt_20" @click="displayWeeklyReportModal({edit:true, rp: weeklyReport})">编辑周报</v-btn>
          </v-flex>
          <v-flex v-if="!!weeklyReport" d-flex align-center justify-start class="width100 mt_30">
            <TabFirstItem :active="activeWeeks" :report="weeklyReport" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
          </v-flex>
          <v-flex v-else d-flex align-center justify-start class="width100 mt_30 empty_weekly_summary">
            <span class="width100 empty_contain">
              <p>您尚未提交当周周报</p>
              <p>总结工作更有助于效率提升哦~</p>
            </span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <!-- custome week calendar -->
    <v-flex shrink>
      <week-calendar :expireds="expireds" :actives="activeWeeks" @range="setRange" @pick="pickWeek" :current="current"></week-calendar>
    </v-flex>
  </v-layout>
  <v-divider class="width100"></v-divider>
  <TabFirstItem :active="activeWeeks" v-if="allWeeklyReport.length != 0" v-for="(rp, index) in monthReport" :key="index" :report="rp" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
  <WeeklyReportModal ref="weeklyReport" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
</v-container>
</template>

<script>
import TabFirstItem from '@/components/summary/TabFirstItem.vue';
import WeeklyReportModal from '@/components/summary/WeeklyReportModal.vue';
import {
  mapGetters,
  mapActions
} from 'vuex';
export default {
  data() {
    return {
      current: null,
      pickedWeek: null,
      reportDate: null,
      re_type: "weekly",
      activeWeeks: [],
      week: null,
      expireds: [],
      weeks: []
    }
  },
  created() {
    const today = new Date();
    this.current = {
      year: today.getFullYear(),
      month: today.getMonth()
    }
    this.getAllWeeklyReportWithCreator({
      rp_creator: this.authUser.id,
      rp_type: 1
    });
    this.getUsers();
  },
  components: {
    TabFirstItem,
    WeeklyReportModal,
    WeekCalendar: () => import('@/components/summary/WeekCalendar.vue')
  },
  computed: {
    ...mapGetters('report', ['allWeeklyReport']),
    ...mapGetters('auth', ['authUser']),
    filteredReport: function () {
      this.activeWeeks = [];
      this.expireds = [];
      if (!!this.pickedWeek) {
        let temp = this.allWeeklyReport.filter(report => {
          if (report.rp_date.includes('/')) {
            const month = report.rp_date.split('-')[1].split('/')[0];
            const weekend = this.getWeekEnd(report.rp_date).getTime();
            const publishDate = new Date(report.created_at).getTime();
            if(publishDate > weekend) {
              this.expireds.push(report.rp_date);
            } else {
              this.activeWeeks.push(report.rp_date);
            }            
            if (Number(month) == this.pickedWeek.month) {
              return report;
            }
          }
        })
        return temp;
      }
      return [];
    },
    monthReport: function () {
      let temp = [];
      if (!!this.pickedWeek) {
        temp = this.filteredReport.filter(report => {
          const week = report.rp_date.split('-')[1].split('/')[1];
          if (Number(week) !== Number(this.pickedWeek.week)) {
            return report;
          }
        })
      }
      return temp;
    },
    weeklyReport: function () {
      if (!!this.pickedWeek) {
        const index = this.filteredReport.findIndex(report => {
          const week = report.rp_date.split('-')[1].split('/')[1];
          if (Number(week) == Number(this.pickedWeek.week)) {
            return report;
          }
        })
        if (index !== -1) {
          return this.filteredReport[index];
        }
      }
    }
  },
  methods: {
    ...mapActions('report', ['getWeeklyReport', 'getAllWeeklyReportWithCreator']),
    ...mapActions('user', ['getUsers']),
    handle_displayRightSummaryEdit(prop) {
      let param = {
        value: prop,
        type: "weekly",
        week: this.week,
      }
      this.$emit("displayRightSummaryEdit", param);
    },
    setDateOfWeeklyReport(prop) {
      let weekNumber = prop.name.split('第')[1].split("周")[0];
      let param = {
        "rp_creator": this.authUser.id,
        "rp_date": this.re_year + "-" + this.re_month + "/" + weekNumber,
        "rp_type": 1,
      }
      this.weekName = constant.weekValue.filter((i) => i.id == weekNumber)[0].value;
      this.getWeeklyReport(param);
    },
    displayWeeklyReportModal(prop) {
      this.$refs.weeklyReport.displayWeeklyReportModal({
        ...prop,
        rp_date: this.pickedWeek,
        rp_range: this.week
      });
    },
    pickWeek(e) {
      this.reportDate = e;
      this.pickedWeek = {
        year: e.split('-')[0],
        month: e.split('-')[1].split('/')[0],
        week: e.split('-')[1].split('/')[1]
      }
    },
    setRange(e) {
      this.week = e;
    },
    getWeekEnd(vWeek) {
      let weeks = [];
      const month = vWeek.split('-')[1].split('/')[0];
      const year = vWeek.split('-')[0];
      const week = vWeek.split('-')[1].split('/')[1];
      const mDays = new Date(year, month, 0).getDate(); // counts of days of month
      const startMWeekDay = new Date(year, month-1, 1).getDay() == 0 ? 7 : new Date(year, month-1, 1).getDay(); // get week day, Monday = 7
      let firstDay = null;
      if (startMWeekDay !== 1) {
        firstDay = new Date(year, month-1, 9 - startMWeekDay);
      } else {
        firstDay = new Date(year, month-1, 1);
      }
      const weeksCount = Math.ceil((mDays - firstDay.getDate()) / 7);
      for (let i = 0; i < weeksCount; i++) {
        const start = new Date(firstDay);
        start.setDate(start.getDate() + i * 7);
        const end = new Date(start);
        end.setDate(end.getDate() + 6);
        weeks.push({
          number: i + 1,
          start: start,
          end: end
        })
      }
      const index = weeks.findIndex(w => w.number == Number(week));
      const last = weeks[index]?.end;
      last.setDate(last.getDate() + 1); 
      return last;
    },
  }
}
</script>

<style>
.width100 {
  width: 100%
}

.m_0 {
  margin: 0px;
}

.p_0 {
  padding: 0px;
}

.weekly_title {
  font-size: 20px !important;
  color: #555 !important;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif !important;
  margin-right: 10px;
}

.weekly_value {
  font-size: 20px !important;
  color: #555 !important;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif !important;
}

.mt_20 {
  margin-top: 20px;
}

.mt_30 {
  margin-top: 30px;
}

.summary_weekly {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty_weekly_summary {
  padding: 30px 25px;
  border: dashed 1px #c5c5c5;
}

.empty_contain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 22px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
  color: #c5c5c5;
}

.weekPicker .v-picker__title {
  display: none !important;
}

.item_week {
  min-height: 29px !important;
  height: 29px;
  margin-bottom: 15px;
  background: rgb(237 244 245);
  border-radius: 5px;
}
</style>
