<template>
<div class="tab-1-content">
  <v-row>
    <v-col cols="9">
      <h2 class="mb-4 title">我的日报 {{ pickedDate.year }}年{{ pickedDate.month + 1 }}月{{ pickedDate.date }}日（星期{{ weekDays[pickedDate.dayOfWeek] }}）</h2>
      <div class="mb-8">
        <v-btn dark v-if="!dailyReport" color="#1B6CF1" @click="displayDailyReportModal({ edit: false })">写日报</v-btn>
        <v-btn dark v-else color="primary" @click="displayDailyReportModal({edit: true, rp: dailyReport})">编辑日报</v-btn>
      </div>
      <TabFirstItem :active="activeDates" v-if="!!dailyReport" :report="dailyReport" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
      <div v-else class="rect">
        <div>您尚未提交当日日报</div>
        <div>总结工作更有助于效率提升哦~</div>
      </div>
    </v-col>
    <!-- custome calendar -->
    <v-col cols="3">
      <date-calendar :expireds="expiredDates" :actives="activeDates" @pick="pickDate" :current="current"></date-calendar>
      <!-- <v-date-picker v-model="datePicker" :first-day-of-week="0" locale="zh-cn" @input="setDateOfDailyReport" class="dayPicker"></v-date-picker> -->
    </v-col>
  </v-row>
  <div class="line my-8"></div>
  <TabFirstItem :active="activeDates" v-if="allDailyReport.length != 0" v-for="(rp, index) in allReports" :key="index" :report="rp" :type="re_type" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
  <DailyReportModal ref="dailyReport" @displayRightSummaryEdit="handle_displayRightSummaryEdit" />
</div>
</template>

<script>
import TabFirstItem from '@/components/summary/TabFirstItem.vue';
import DailyReportModal from '@/components/summary/DailyReportModal.vue';
import {
  constant
} from '@/constants/constant.js';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'TabFirstComponent',
  data() {
    return {
      re_type: "daily",
      datePicker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      pickedDate: null,
      current: null,
      weekDays: ['日', '一', '二', '三', '四', '五', '六'],
      activeDates: [],
      expiredDates: [],
    }
  },
  components: {
    TabFirstItem,
    DailyReportModal,
    DateCalendar: () => import('@/components/summary/DateCalendar.vue'),
  },
  computed: {
    ...mapGetters('report', ['allDailyReport']),
    ...mapGetters('auth', ['authUser']),
    filteredReports: function () {
      return this.allDailyReport.filter(report => {
        const rpYear = Number(report.rp_date.split('-')[0]);
        const rpMonth = Number(report.rp_date.split('-')[1]);
        if(rpYear == Number(this.pickedDate.year) && rpMonth == Number(this.pickedDate.month +1)) {
          return report;
        }
      })
    },
    allReports: function () {
      this.activeDates = [];
      this.expiredDates = [];
      return this.filteredReports.filter(report => {
        const rDate = new Date(report.rp_date);
        rDate.setDate(rDate.getDate() + 1);
        const publishDate = new Date(report.created_at).getTime();
        if(publishDate > rDate.getTime()) {
          this.expiredDates.push(report.rp_date);
        } else {
          this.activeDates.push(report.rp_date);
        }        
        const rpDate = Number(report.rp_date.split('-')[2]);
        if(rpDate != Number(this.pickedDate.date)) {
          return report;
        }
      })
    },
    dailyReport: function () {
      const index = this.filteredReports.findIndex(report => {
        const rpDate = Number(report.rp_date.split('-')[2]);
        if(rpDate == Number(this.pickedDate.date)) {
          return report;
        }
      })
      return this.allDailyReport[index];
    }
  },
  methods: {
    ...mapActions('report', ['getAllDailyReportWithCreator']),
    ...mapActions('user', ['getUsers']),
    displayDailyReportModal(prop) {
      this.$refs.dailyReport.displayModal({
        ...prop,
        rp_date: this.pickedDate
      });
    },
    handle_displayRightSummaryEdit(prop) {
      let param = {
        value: prop,
        type: "daily",
      }
      this.$emit("displayRightSummaryEdit", param);
    },
    setDateOfDailyReport(prop) {

    },
    pickDate(e) {
      this.pickedDate = Object.assign({}, e);
    }
  },
  created() {
    this.getAllDailyReportWithCreator({
      "rp_creator": this.authUser.id,
      "rp_type": 0
    });
    // get today
    const today = new Date();
    this.pickedDate = {
      year: today.getFullYear(),
      month: today.getMonth(),
      date: today.getDate(),
      dayOfWeek: today.getDay()
    }
    this.current = {
      year: today.getFullYear(),
      month: today.getMonth(),
      date: today.getDate(),
      dayOfWeek: today.getDay()
    };
    this.getUsers();
  },
}
</script>

<style>
.title {
  font-size: 20px;
  line-height: 36px;
  color: #555;
}

.rect {
  border: 1px dotted #ddd;
  text-align: center;
  padding: 30px 0px;
  font-size: 22px;
  color: #c5c5c5;
}

.line {
  border-top: 2px solid #E3ECF1;
}

.report {
  padding: 20px;
}

.w-100 {
  width: 100%;
}

.date-info {
  border-left: 4px solid #2d83cf;
  font-size: 16px;
  color: #2d83cf;
}

.left-line {
  border-left: 6px solid #e3ecf1;
  padding-left: 8px;
  font-size: 16px;
  font-family: "Helvetica Neue", Tahoma, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STHeitiSC, sans-serif;
}

.time-fb {
  line-height: 16px;
  color: #ccc;
  font-size: 12px;
}

.action-tag {}

.summary_intercom {
  cursor: pointer;
}

.summary_intercom:hover .message_icon {
  color: rgb(105 167 206) !important;
}

.summary_intercom:hover span {
  color: rgb(105 167 206);
}

.summary_intercom_p_0 {
  padding: 0px !important;
  transition: all ease 1s;
}

.mtb_15 {
  margin: 15px 0px;
}

.dayPicker .v-picker__title {
  display: none !important;
}
</style>
