<template>
<div class="report d-flex justify-start align-start width100">
  <UserAvatar :name="authUser.employeeName" width="50px" height="50px" />
  <div class="report-content ml-4 width100">
    <div class="d-flex justify-space-between align-center mb-2">
      <div>
        <span style="color: #0a131a; font-weight: 700;">{{ report.rp_creator | username }}</span>
        <span v-if="type=='daily'">的日报</span>
        <span v-if="type=='weekly'">的周报</span>
        <span v-if="type=='monthly'">的月报</span>
      </div>
      <div class="grey--text teal lighten-5 pa-2" v-if="report.rp_visible_range==0">
        <v-icon small>mdi-lock-outline</v-icon>
        <span>仅相关人可见</span>
      </div>
      <div class="grey--text teal lighten-5 pa-2" v-if="report.rp_visible_range==1">
        <v-icon small>mdi-lock-outline</v-icon>
        <span>仅发起者的部门可见</span>
      </div>
      <div class="grey--text teal lighten-5 pa-2" v-if="report.rp_visible_range==2">
        <!-- <v-icon small>mdi-lock-outline</v-icon> -->
        <span>所有人可见</span>
      </div>
    </div>
    <div class="date-info my-4">
      <span class="mx-2" v-if="type=='daily'">日报</span>
      <span class="mx-2" v-if="type=='weekly'">周报</span>
      <span class="mx-2" v-if="type=='monthly'">月报</span>
      <span v-if="type=='daily'" style="cursor: pointer;" @click="displayRightSummaryEdit">{{ getYear }}年{{ getMonth }}月{{ getDate }}日日报（{{ getDayOfWeek }}）</span>
      <span v-if="type=='weekly'" style="cursor: pointer;" @click="displayRightSummaryEdit">{{ getYear }}年{{ getMonth }}月第{{ getWeek }}周周报</span>
      <span v-if="type=='monthly'" style="cursor: pointer;" @click="displayRightSummaryEdit">{{ getYear }}年{{ getMonth }}月月报 </span>
      <span v-if="active.includes(report.rp_date)">
        <v-icon color="green">mdi-check</v-icon>
        <span class="green--text">按时提交</span>
      </span>
      <span v-else>
        <v-icon color="purple">mdi-check</v-icon>
        <span class="purple--text">延时提交</span>
      </span>
    </div>
    <div class="overview my-4">
      <div class="mr-4 d-flex">
        <div v-if="report.rp_completed_tasks > 0" class="d-flex mx-2">
          <div v-if="type=='daily'">当日完成任务</div>
          <div v-if="type=='weekly'">当周完成任务</div>
          <div v-if="type=='monthly'">当月完成任务</div>
          <div class="ml-3 green--text">{{ report.rp_completed_tasks }}</div>
        </div>
        <div v-if="report.rp_expired_tasks > 0" class="d-flex mx-2">
          <div v-if="type=='daily'">当日过期任务</div>
          <div v-if="type=='weekly'">当周过期任务</div>
          <div v-if="type=='monthly'">当月过期任务</div>
          <div class="red--text ml-3">{{ report.rp_expired_tasks }}</div>
        </div>
        <div v-if="report.rp_progress_tasks > 0" class="d-flex mx-2">
          <div v-if="type=='daily'">当日进行中任务</div>
          <div v-if="type=='weekly'">当周进行中任务</div>
          <div v-if="type=='monthly'">当月进行中任务</div>
          <div class="blue--text ml-3">{{ report.rp_progress_tasks }}</div>
        </div>
      </div>
    </div>
    <div class="left-line my-4">
      <span>总结与障碍</span>
    </div>
    <div class="my-4">
      <span>{{ getAbstract }}</span>
    </div>
    <div class="left-line my-4">
      <span>下一步工作安排</span>
    </div>
    <div class="my-4">
      <span>{{ getTodo }}</span>
    </div>
    <div>
      <v-chip color="#B0C4DE" class="ma-1" outlined v-for="(file, i) in report.rp_files" label :key="i">
        <div class="d-flex px-3">
          <div class="blue--text text-caption">
            {{ file.file_name }}
          </div>
        </div>
      </v-chip>
    </div>
		<LikingComponent rp_type="4" :rp_id="report.rp_id" :curTime="getFullTime.month+'/'+ getFullTime.day +' '+ getFullTime.hour +':'+ getFullTime.minute" />    
    <v-divider class="width100 mtb_15"></v-divider>
  </div>
</div>
</template>

<script>

import UserAvatar from '@/components/common/UserAvatar.vue';
import {
  constant
} from '@/constants/constant.js';
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  props: ['report', 'type', 'active'],
  data() {
    return {
      showIntercomWindow: false,
      weekDays: ['日', '一', '二', '三', '四', '五', '六']
    }
  },
  components: {
    UserAvatar,
		LikingComponent:() => import('@/components/summary/LikingComponent.vue'),
  },
	mounted() {
    this.getLabels();
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
    getYear() {
      if (!!this.report) {
        return this.report.rp_date.split("-")[0];
      }
    },
    getMonth() {
      if (!!this.report) {
        return this.report.rp_date.split('-')[1].split('/')[0];
      }
      return '';
    },
    getWeek() {
      if (!!this.report) {
        if (this.type == "weekly") {
          let week = parseInt(this.report.rp_date.split("-")[1].split("/")[1]);
          //   return constant.weekValue.filter((d) => d.id == week)[0].value;
          return week;
        } else {
          return '';
        }
      }
    },
    getDate() {
      return this.report.rp_date.split("-")[2];
    },
    getDayOfWeek() {
      if (!!this.report) {
        if (this.type == "daily") {
          let date = new Date(this.report.rp_date);
          return `星期${this.weekDays[date.getDay()]}`;
        }
      }
      return '';
    },
    getAbstract() {
      if (!!this.report) {
        return this.report.rp_abstract.replace(/<[^>]+>/g, '');
      }
    },
    getTodo() {
      if (!!this.report) {
        return this.report.rp_todo.replace(/<[^>]+>/g, '');
      }
    },
    getFullTime() {
      if (!!this.report) {
        let date = new Date(this.report.created_at);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = date.getDate();
        let hour = date.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        let minute = date.getMinutes();
        if (minute < 10) {
          minute = "0" + minute;
        }
        return {
          year: year,
          month: month,
          day: day,
          hour: hour,
          minute: minute,
        }
      }
    },
  },
  methods: {
    ...mapActions('label', ['getLabels']),
    displayRightSummaryEdit() {
      this.$emit("displayRightSummaryEdit", this.report);
    },
    handle_showEditWindow() {
      this.showIntercomWindow = true;
    },
  }
}
</script>

<style>
.width100 {
  width: 100%;
}
</style>
